.home-wrapper {
    position:relative;
    left: 50%;
    transform: translateX(-50%);
    height: 100vh;
}

.home-canvas {
    position: absolute;
    left: 50%;
    margin-left: -25vw;
    display: block;
    width: 100%;
    z-index: 10;
}

@media only screen and (max-width: 425px) {
    .home-canvas {
        left: 10%;
        margin-left: -5vw;
    }
}


