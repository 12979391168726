.standard-site-wrapper{
    
    display: flex;
    flex-direction: column;
    align-items: center;
}
.standard-site-container {
    margin: 10px;
    width: 80%;
}


@media only screen and (max-width: 425px) {
    .standard-site-container {
        width: 95%;
    }
}