.header-logo {
    height: 7vw;
    width: 30vh;
}

.profile-picture {
    height: 200px;
    border-radius: 50%;
} 

.about-contact-icon {
    &-github{
        cursor: pointer;
        height:70px;
        border: 2px solid black;
        border-radius: 100%;
    }
    &-linkedin{
        cursor: pointer;
        height: 63px;
        border: 2px solid black;
        border-radius: 100%;
    }
    &-email{
        cursor: pointer;
        height: 63px;
        border: 2px solid black;
        border-radius: 100%;
    }
}

.about-contact-icon {
    &-github:hover{
        border: 2px solid #2AF782;
    }
    &-linkedin:hover{
        border: 2px solid #2AF783;
    }
    &-email:hover{
        border: 2px solid #2AF783;
    }
}

.card-front-image {
    height: 300px;
    width: 300px;
}

.skill-badge-image {
    width: 120px;
    height: auto;
    margin: 25px 25px 25px 25px;
}

.skill-badge-image-long {
    width: 120px;
    height: auto;
    margin: 25px 15px 25px 15px;
}

.carousel-image {
    width: 70vw;
    height: auto;
    margin: 15px 0;
}

@media only screen and (min-width: 1440px) {
    .header-logo {
        height: 10vw;
        width: 35vh;
    }
}

@media only screen and (min-width: 1200px) {
    .header-logo {
        height: 10vw;
        width: 35vh;
    }
    
}

@media only screen and (max-width: 768px) {
    .header-logo {
        height: 12vw;
        width: 30vh;
    }
    .profile-picture {
        height: 150px;
        width: 150px;
        margin-bottom: 20px;
    } 
    .about-contact-icon {
        &-github{
            height: 60px;
        }
        &-linkedin{
            height: 50px;
        }
        &-email{
            height: 50px;
        }
    }
    .skill-badge-image-long {
        width: 90px;
        margin: 10px 10px 10px 10px;
    }
    .skill-badge-image {
        width: 80px;
        margin: 10px 10px 10px 10px;
    }
}

.footer-contact-icon-github{
    height: 50px;
    margin-top: 10px;
}
.footer-contact-icon-linkedin{
    height: 45px;
    margin: 0px 15px 0px 15px;
}
.footer-contact-icon-email{
    height: 45px;
    margin: 10px 0px 0px 0px;
}


@media only screen and (max-width: 768px) {
    .footer-contact-icon-github{
        height: 35px;
    }
    .footer-contact-icon-linkedin{
        height: 33px;
    }
    .footer-contact-icon-email{
        height: 33px;
    }
}

@media only screen and (max-width: 425px) {
    .header-logo {
        height: 20vw;
    }
}

