.skills-wrapper{
    display : grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    margin-bottom: 200px;
    padding-top: 150px;
}

@media only screen and (max-width: 425px) {
    .skills-wrapper{
        display : grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
    }
}