.navbar {
    &-wrapper{
        position: sticky;
        top: 0;
        z-index: 100;
        background-color: black;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        padding: 20px 20px 10px 0;
    }
    &-link{
        color: white;
        text-decoration: none;
        cursor: pointer;
        font-size: 20px;
        font-family: $press-start-font-family;
        margin-left: 1em;
    }
}

.active {
    color: #34BDCF;
}


@media only screen and (max-width: 768px) {
    .navbar {
        &-link{
            font-size: 18px;
        }
    }
}

@media only screen and (max-width: 425px) {
    .navbar {
        &-wrapper{
            margin-top: 5px;
            padding: 15px 0 20px 0;
        }
        &-link{
            font-size: 14px;
        }
    }
}