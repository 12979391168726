.about-container {
    height: 85vh;
    display: flex;
    justify-content: center;
    align-content: center;
}

.about-content-container {
    display: flex;
    width: 90%;
    margin-top: 10vh;
}

.text-container {
    margin-left: 5vw;
}

.contact-icon-wrapper {
    display: flex;
    width: 90%;
    margin-left: 5vw;
    align-items: center;
}

@media only screen and (max-width: 425px) {
    .about-content-container {
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin-top: 3vh;
    }
    .contact-icon-wrapper {
        margin-left: 0vw;
        justify-content: center;
    }
}