.footer-wrapper{
    display: flex;
    justify-content: space-between;
    border-top: 5px solid white;
    align-items: center;
}

.footer-text{
    font-family: $press-start-font-family;
    font-size: 16px;
    padding-top: 10px;
}

.heart{
    font-size: 24px;
}

@media only screen and (max-width: 768px) {
    .footer-text{
        font-size: 10px;
    }
}

@media only screen and (max-width: 425px) {
    .footer-wrapper{
        flex-direction: column;
    }
}