.about-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 40px;
}

.countries-link {
    color: #7DA8F7;
    cursor: pointer;
}
.contact-icon-spacer {
    width: 40px;
}

@media only screen and (max-width: 768px) {
    .about-text {
        font-size: 18px;
        margin-bottom: 20px;
    }
}