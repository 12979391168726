.start-btn{
    font-family: $press-start-font-family;
    background-color: $alt-button-background;
    text-align: center;
	display: inline-block;
    width: 300px;
    height: 50px;
	margin:5px;
    font-weight: bold;
    font-size: 30px;
    padding: 10px 0 10px 10px ;
    text-shadow: -3px -3px black, 1px 1px $alt-shadow-color;
    color: white;
    letter-spacing: 5px;
    -webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	-o-border-radius: 7px;
	border-radius: 7px;
    box-shadow: 0 .2em $alt-shadow-color; 
    cursor: pointer;
}

.start-btn:hover{
    background-color: $button-background;
    box-shadow: 0 .2em $shadow-color;
}