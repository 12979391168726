.work-details-layout {
    display: flex;
    flex-direction: column;
}

.work-details-link-container {
    display: flex;
    justify-content: space-evenly;
}

.carousel-container-layout {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.carousel-container {
    width: 70%;
    margin-bottom: 25px;
}

.work-details-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.work-details-text-container {
    width: 90%;
    padding: 25px 0 100px 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.additional-assets-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
}

.additiona-assets-header {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0 0 5px 0;
    font-size: 1em;
}

.additional-assets-link-container {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

@media only screen and (max-width: 2560px) {
    .carousel-container {
        width: 80%;
    }
}

@media only screen and (min-width: 1024px) {
    .work-details-text-container {
        padding: 20px 0 20px 0;
    }
}

@media only screen and (max-width: 768px) {
    .carousel-container {
        width: 90%;
    }
    .work-details-text-container {
        padding: 15px 0 15px 0;
    }
}

@media only screen and (max-width: 425px) {
    .carousel-container {
        width: 90%;
    }
    .work-details-text-container {
        display: flex;
        flex-direction: column;
    }
}