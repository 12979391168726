@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

$default-link-color: green;
$outline-width: 3px;
$shadow-width: 6px;

$button-background: #92CD41;
$sec-button-background: #76c442;
$shadow-color:  #4AA52E;

$alt-button-background: #F7D51D;
$alt-sec-button-background: #F2C409;
$alt-shadow-color:  #E59400;

$reset-button-background: #E76E55;
$reset-sec-button-background: #CE372B;
$reset-shadow-color:  #8C2022;

$press-start-font-family: 'Press Start 2P'