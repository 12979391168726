.link {
    @include link-styles
}

.work-card-more-details {
    font-family: $press-start-font-family;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: #7DA8F7;
    padding: 0 5px 5px 5px;
    margin-bottom: 11px;
}

.work-card-more-details:hover {
    border-bottom: 1px solid #7DA8F7;
    margin-bottom: 10px;
}


.work-details-link-available {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: $press-start-font-family;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: #7DA8F7;
    padding: 0 5px 5px 5px;
    margin-bottom: 11px;
}

.work-details-link-available:hover {
    border-bottom: 1px solid #7DA8F7;
    margin-bottom: 10px;
}

.work-details-link-not-available {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: $press-start-font-family;
    font-size: 16px;
    cursor: default;
    text-decoration: none;
    color: #7DA8F7;
    padding: 0 5px 5px 5px;
    margin-bottom: 11px;
}

.additional-resources-link {
    font-family: Arial, Helvetica, sans-serif;
    color: #7DA8F7;
    cursor: pointer;
    padding: 0 20px 0 20px;
    text-decoration: none;
}

.additional-resources-link:hover {
    color: #B4D1FF;
}


@media only screen and (min-width: 768px) {

    .work-details-link-available {
        font-size: 14px;
    }

    .work-details-link-not-available {
        font-size: 14px;
    }
}

@media only screen and (max-width: 425px) {

    .work-details-link-available {
        font-size: 12px;
    }

    .work-details-link-not-available {
        font-size: 12px;
    }
}