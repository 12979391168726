.header-wrapper {
    display: grid;
    grid-template-columns: 200px auto 200px;
    overflow: hidden;
    position: relative;
    height: 100%;
    padding-top: 10px;
}

.score-container {
    grid-column-start: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.header-logo-container{
    grid-column-start: 2;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.lives-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.lives-icons-container {
    grid-column-start: 3;
    display: flex;
}


@media only screen and (max-width: 425px) {
    .header-wrapper {
        grid-template-columns: 10px auto 10px;
    }
}