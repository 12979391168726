.game-message{
    color: green;
    font-size: 3em;
    font-weight: bold;
}

.game-alert{
    color: red;
}

.game-intro-container{
  margin-top: 100px;
  width: 100%;
}

.intro-text-line{
  position: relative;
  top: 100%;  
  width: 100%;
  margin: 0 auto;
  border-right: 2px solid rgba(255,255,255,.75);
  font-size: 2em;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-sizing:content-box;
  box-sizing:content-box;
}

.animate-typewriter-cursor{
  width:0;
}

.player-icon {
  width: 60px;
  height: 40px;
  padding: 3px;
}

.player-hidden {
  display: none;
}

.bullet {
  display: none;
}