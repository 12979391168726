.game-canvas-container{
    display: flex;
    flex-direction: column;
    border: 2px solid cornflowerblue;
    height: 80vh;
}

.game-enemy-container {
    background-color: grey;
}

.game-shooter-container{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    width: 98%;
}