.work-details-title {
    font-family: $press-start-font-family;
    color: #2AF783;
    font-size: 24px;
    text-align: center;
}

.work-details-text-header {
    font-family: $press-start-font-family;
    font-size: 18px;
    margin: 0;
}

.description {
    color: #36F7F7;
}

.languages {
    color: #F71705;
}

.objective {
    color: #41F71E;
}

.challenges {
    color: #F76611;
}

.learned {
    color: #F743C7;
}

.tags {
    color: #E2F75C;
}

.work-details-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    margin: 0;
}

@media only screen and (min-width: 2560px) {
    .work-details-text {
        font-size: 24px;
    }
}

@media only screen and (min-width: 1440px) {
    .work-details-text {
        font-size: 20px;
    }
}

@media only screen and (min-width: 768px) {
    .work-details-title {
        font-size: 20px;
    }
    .work-details-text-header {
        font-size: 14px;
    }
    .work-details-text {
        font-size: 16px;
    }
}

@media only screen and (max-width: 425px) {
    .work-details-title {
        font-size: 18px;
    }
    .work-details-text-header {
        font-size: 14px;
    }
    .work-details-text {
        padding: 20px 0 0 0;
    }
}