.work-input-container {
    display: flex;
    position: relative;
    padding-left: 50px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.work-input-label {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin-top: 5px;
}

  /* Hide default checkbox */
.work-input-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

  /* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 3px solid #F7A81E;
    border-radius: 8px;
}

  /* On mouse-over, add a grey background */
.work-input-container:hover input ~ .checkmark {
    background-color: #3E3D3D;
}

  /* When the checkbox is checked, add a subtle background */
.work-input-container input:checked ~ .checkmark {
    background-color: #222;
}

  /* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

  /* Show the checkmark when checked */
.work-input-container input:checked ~ .checkmark:after {
    display: block;
}

  /* Style the checkmark */
.work-input-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media only screen and (max-width: 1024px) {
    .work-input-container {
        padding-left: 40px;
    }
    .work-input-label {
        font-size: 18px;
    }
    .checkmark {
        height: 20px;
        width: 20px;
    }
    .work-input-container .checkmark:after {
        left: 6px;
        top: 2px;
    }
}

@media only screen and (max-width: 768px) {
    .work-input-container {
        padding-left: 30px;
        padding-right: 10px;
    }
    .work-input-label {
        font-size: 16px;
    }
    .checkmark {
        height: 16px;
        width: 16px;
        border-radius: 5px;
    }
    .work-input-container .checkmark:after {
        left: 4px;
        top: 0px;
    }
}

@media only screen and (max-width: 320px) {
    .work-input-label {
        font-size: 14px;
    }
}