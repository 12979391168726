.work-layout{
    display: flex;
    flex-direction: column; 
}

.work-filter-layout {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 50px;
}

.work-filter-container {
    border: 2px solid #2AF783;
    border-radius: 10px;
    width: 80%;
    padding: 10px;
}

.work-filter-input-group{
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    height: 50px;
    margin-left: 4vw;
}

.work-list-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px,1fr));
    grid-gap: 1em;
}

@media only screen and (max-width: 1024px) {
    .work-filter-input-group {
        margin-left: 2vw;
    }
}

@media only screen and (max-width: 425px) {
    .work-filter-input-group{
        grid-template-columns: 1fr 1fr ;
    }
}
